import React from "react"
import SEO from '../components/PageSEO';
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import About from '../containers/company/about'
import Team from '../containers/company/team'
import JoinOurTeam from '../containers/company/join-our-team'
import Testimonial from '../containers/company/testimonial'

export default () => (
    <Layout>
        <SEO title="Furkan Acar Kimdir? - Mimar &amp; Gayrimenkul Değerleme Uzmanı"
            description="2017'den Mimarlığa başladığımdan beri, firmalar ve işletmeler için başarılı bir şekilde mimari yapılar tasarlıyor ve mimarlık hizmeti veriyorum." />
        <Banner />
        <About />
        <Team />
        <JoinOurTeam />
        <Testimonial />
    </Layout>
)
